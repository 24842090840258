import logo from './logo.svg';
import './App.css';
import Header from './Component/Header';
import Home from './Pages/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import About from './Pages/About';
import Service from './Pages/Service';
import Price from './Pages/Price';
import Booking from './Pages/Booking';
import Contact from './Pages/Contact';
import Car from './Pages/Car';
function App() {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/" component={Home}/>
        <Route exact={true} path="/about-us" component={About}/>
        <Route exact={true} path="/our-services" component={Service}/>
        <Route exact={true} path="/our-price" component={Price}/>
        <Route exact={true} path="/booking" component={Booking}/>
        <Route exact={true} path="/contact" component={Contact}/>
        <Route exact={true} path="/our-vehicle" component={Car}/>
      </Switch>
    </Router>
    // <Home/>    

    
  );
}

export default App;
