import React, { useState } from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import axios from "axios";
import swal from "sweetalert";
import $ from "jquery";
import { Formik } from "formik";

const Booking = () => {
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [adult, setAdult] = useState("");
  const [child, setChild] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [comment, setComment] = useState("");

  // states for geting data from form start end

  return (
    <>
      <div id="wrapper" className="hasbg transparent">
        <Header />
        <div className="ppb_wrapper">
          <div
            id="page_caption"
            className="hasbg parallax  withtopbar  blog_wrapper"
            style={{
              backgroundImage: "url(upload/Home-BANNER.jpg)",
              height: "15rem",
            }}
          >
            <div className="page_title_wrapper">
              <div className="page_title_inner">
                <div className="page_title_content">
                  <h1 className="withtopbar">Booking</h1>
                </div>
              </div>
            </div>
          </div>
          {/* Begin content */}
          <div
            id="page_content_wrapper"
            className="hasbg withtopbar blog_wrapper booking-wrapper-form"
          >
            <div className="ppb_wrapper hasbg withtopbar">
              <div className="single_car_booking_wrapper themeborder book_instantly">
                <div
                  role="form"
                  className="wpcf7"
                  id="wpcf7-f5-o1"
                  lang="en-US"
                  dir="ltr"
                >
                  <div className="screen-reader-response" />
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      adult: "",
                      child: "",
                      pickupaddress: "",
                      dropoffaddress: "",
                      phonenumber: "",
                      pickupdate: "",
                      pickuptime: "",
                      comment: "",
                    }}
                    validate={(values) => {
                      let errors = {};
                      if (values.name.length <= 0) {
                        errors = {
                          name: "This Field is Required",
                        };
                      } else if (!values.email) {
                        errors.email = "Email is Required";
                      }
                      //  else if (!values.adult) {
                      //   errors.adult = "Adult Field is Required";
                      // } else if (!values.child) {
                      //   errors.child = "Child Field is Required";
                      // } 
                      else if (!values.pickupaddress) {
                        errors.pickupaddress = "Address is Required";
                      } else if (!values.dropoffaddress) {
                        errors.dropoffaddress = "Drop off is Required";
                      } else if (!values.phonenumber) {
                        errors.phonenumber = "Phone Number is Required";
                      } else if (!values.pickupdate) {
                        errors.pickupdate = "Date is Required";
                      } else if (!values.pickuptime) {
                        errors.pickuptime = "Time is Required";
                      } 
                      // else if (!values.comment) {
                      //   errors.comment = "Comment is Required";
                      // } 
                      else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address format";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      console.clear();
                      console.log("this form submitting");
                      // const sendBooking=(e)=>{
                        //   e.preventDefault();
                        
                      console.log("submited values", JSON.stringify(values));
                      // const form = new FormData();
                      // form.append("contact_name", values.name);
                      // form.append("email", values.email);
                      // form.append("adult", values.adult);
                      // form.append("child", values.child);
                      // form.append("pick_up", values.pickupaddress);
                      // form.append("drop_off", values.dropoffaddress);
                      // form.append("phone", values.phonenumber);
                      // form.append("date",values.pickupdate);
                      // form.append("time",values.pickuptime);
                      // form.append("comment",values.comment);
                      // console.log('form data is =>' ,form );
                      const obj = {
                        contact_name: values.name,
                        email: values.email,
                        adult: values.adult,
                        child: values.child,
                        pick_up: values.pickupaddress,
                        drop_off: values.dropoffaddress,
                        phone: values.phonenumber,
                        date: values.pickupdate,
                        time: values.pickuptime,
                        comment: values.comment,
                        // hour: 
                      }
                      axios({
                        method: "POST",
                        url: "https://encore.encorelimo.com.au/api/booking/process",
                        data: obj,
                        headers: {
                          "Accept": "application/json",
                          "Content-Type": "application/json"
                        }
                      }).then(
                        (response) => {
                          swal("Booked", response.data.message, "success");
                          // event.target.reset();

                          // console.log(response)
                        },
                        (error) => {
                          swal("Alert", error + "", "error");
                          console.log(error);
                        }
                      );

                      // }
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      isSubmitting,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="wpcf7-form"
                        noValidate
                      >
                        <p>
                          <label>
                            {" "}
                            Contact Name
                            <br />
                            <span className="wpcf7-form-control-wrap your-name">
                              <input
                                type="text"
                                name="name"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="John Doe"
                                value={values.name}
                              />
                            </span>{" "}
                          </label>
                          <p style={{color:"red"}}>{errors.name && errors.name}</p>
                        </p>
                        <p>
                          <label>
                            {" "}
                            Email Address
                            <br />
                            <span className="wpcf7-form-control-wrap your-email">
                              <input
                                type="email"
                                name="email"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="sample@yourcompany.com"
                                value={values.email}
                                required
                              />
                            </span>{" "}
                          </label>
                          <p style={{color:"red"}}>{errors.email && errors.email}</p>
                        </p>

                        <p>
                          <label>
                            {" "}
                            Adult
                            <br />
                            <span className="wpcf7-form-control-wrap your-name">
                              <input
                                type="number"
                                min="0"
                                name="adult"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="0-1"
                                value={values.adult}
                                required
                              />
                            </span>{" "}
                          </label>
                          {/* <p style={{color:"red"}}>{errors.adult && errors.adult}</p> */}
                        </p>
                        <p>
                          <label>
                            {" "}
                            Child
                            <br />
                            <span className="wpcf7-form-control-wrap your-name">
                              <input
                                type="number"
                                min="0"
                                name="child"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="0-1"
                                value={values.child}
                                required
                              />
                            </span>{" "}
                          </label>
                          {/* <p style={{color:"red"}}>{errors.child && errors.child}</p> */}
                        </p>
                        <p>
                          <label>
                            {" "}
                            Pick up Address
                            <br />
                            <span className="wpcf7-form-control-wrap your-name">
                              <input
                                type="text"
                                min="0"
                                name="pickupaddress"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Pick up Address"
                                value={values.pickupaddress}
                                required
                              />
                            </span>{" "}
                          </label>
                          <p style={{color:"red"}}>{errors.pickupaddress && errors.pickupaddress}</p>
                        </p>
                        <p>
                          <label>
                            {" "}
                            Drop off Address
                            <br />
                            <span className="wpcf7-form-control-wrap your-name">
                              <input
                                type="text"
                                min="0"
                                name="dropoffaddress"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                aria-required="true"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Drop off Address"
                                value={values.dropoffaddress}
                                required
                              />
                            </span>{" "}
                          </label>
                          <p style={{color:"red"}}>{errors.dropoffaddress && errors.dropoffaddress}</p>
                        </p>

                        <p>
                          <label>
                            {" "}
                            Phone Number
                            <br />
                            <span className="wpcf7-form-control-wrap your-tel">
                              <input
                                type="tel"
                                name="phonenumber"
                                size={40}
                                className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                                aria-required="true"
                                aria-invalid="false"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="0000000000"
                                value={values.phonenumber}
                                required
                              />
                            </span>{" "}
                          </label>
                          <p style={{color:"red"}}>{errors.phonenumber && errors.phonenumber}</p>
                        </p>

                        <p>
                          <label>
                            {" "}
                            Pickup Date
                            <br />
                            <span className="wpcf7-form-control-wrap pickup-date">
                              <input
                                type="date"
                                name="pickupdate"
                                className="wpcf7-form-control wpcf7-date wpcf7-validates-as-required wpcf7-validates-as-date"
                                aria-required="true"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                aria-invalid="false"
                                value={values.pickupdate}
                                required
                              />
                            </span>{" "}
                          </label>
                          <p style={{color:"red"}}>{errors.pickupdate && errors.pickupdate}
</p>                        
</p>
                        <p>
                          <label>
                            {" "}
                            Pickup Time
                            <br />
                            <span className="wpcf7-form-control-wrap pickup-time">
                              <input
                                type="time"
                                name="pickuptime"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                aria-required="true"
                                aria-invalid="false"
                                value={values.pickuptime}
                                required
                              />
                            </span>{" "}
                          </label>
                    <p style={{color:"red"}}>{errors.pickuptime && errors.pickuptime}</p>
                        </p>

                        <p>
                          <label>Comment</label>
                          <textarea
                            name="comment"
                            className="wpcf7-form-control wpcf7-submit"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows="6"
                            required
                            value={values.comment}
                          ></textarea>
                          {/* <p style={{color:"red"}}>{errors.comment && errors.comment}</p> */}
                        </p>
                        <p>
                          <input
                            type="submit"
                            disabled={isSubmitting}
                            className="button wpcf7-form-control wpcf7-submit"
                            defaultValue="Request for Booking"
                          />
                        </p>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Booking;
