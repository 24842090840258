import React, { Component } from 'react'
import Footer from '../Component/Footer'
import Header from '../Component/Header'

export default class Service extends Component {
    render() {
        return (
            <>
            <div id="wrapper" className="hasbg transparent">
                <Header/>
                <div className="ppb_wrapper">
                <div
              id="page_caption"
              className="hasbg parallax  withtopbar  blog_wrapper"
              style={{
                backgroundImage: "url(upload/Home-BANNER.jpg)",
                height: "15rem",
              }}
            >
              <div className="page_title_wrapper">
                <div className="page_title_inner">
                  <div className="page_title_content">
                    <h1 className="withtopbar">Our Services</h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
                <div className="one">
                    <div className="one_half_bg parallax" style={{backgroundImage: 'url(upload/airport-trasnfer.jpg)', height: '550px'}} />
                    <div className="one_half_bg" style={{padding: '80px'}}>
                    <h2 className="ppb_title" style={{}}>AIRPORT TRANSFERS</h2>
                    <div className="ppb_header_content">
                        <p>We monitor your flight to make sure of your exact arrival time and that our driver is ready and waiting for you. On arrivals, your chauffer will personally greet you, assist you with your luggage and take you to your destination. Beat the long cues for a taxi and travel with us instead.</p>
                        {/* <p><a className="button" style={{marginTop: '20px'}} href="#">View Plan &amp; Price</a></p> */}
                    </div>
                    </div>
                    <br className="clear" />
                </div>
                <div className="one">
                    <div className="one_half_bg textright" style={{padding: '80px'}}>
                    <h2 className="ppb_title" style={{}}>CORPORATE TRANSFERS</h2>
                    <div className="ppb_header_content">
                        <p>We have over 25 corporate accounts, who regularly turn to us for their transport needs. We streamline your expenses with one simple invoice each month. We get colleagues to share rides
                        where ever possible to reduce costs.
                        </p>
                        {/* <p><a className="button" style={{marginTop: '20px'}} href="#">View Plan &amp; Price</a></p> */}
                    </div>
                    </div>
                    <div className="one_half_bg parallax" style={{backgroundImage: 'url(upload/corporatetransfer.png)', height: '550px'}} />
                    <br className="clear" />
                </div>
                <div className="one">
                    <div className="one_half_bg parallax" style={{backgroundImage: 'url(upload/event.jpg)', height: '550px'}} />
                    <div className="one_half_bg" style={{padding: '80px'}}>
                    <h2 className="ppb_title" style={{}}>EVENTS</h2>
                    <div className="ppb_header_content">
                        <p>We can arrange multi-trip group bookings, aggregate passengers where possible, organise buses if necessary and ensure all participants arrive on time.</p>
                        {/* <p><a className="button" style={{marginTop: '20px'}} href="#">View Plan &amp; Price</a></p> */}
                    </div>
                    </div>
                    <br className="clear" />
                </div>
                <div className="one">
                    <div className="one_half_bg textright" style={{padding: '80px'}}>
                    <h2 className="ppb_title" style={{}}>FAMILY DRIVER</h2>
                    <div className="ppb_header_content">
                        <p>You’ll enjoy peace of mind knowing your personal Encore Limo driver is safely chauffeuring your loved ones.
                        </p>
                        {/* <p><a className="button" style={{marginTop: '20px'}} href="#">View Plan &amp; Price</a></p> */}
                    </div>
                    </div>
                    <div className="one_half_bg parallax" style={{backgroundImage: 'url(upload/e747a1c4306f43b1e4c5b4be6bfb787e.jpg)', height: '550px'}} />
                    <br className="clear" />
                </div>
                <div className="one">
                    <div className="one_half_bg parallax" style={{backgroundImage: 'url(upload/tours.jpg)', height: '550px'}} />
                    <div className="one_half_bg" style={{padding: '80px'}}>
                    <h2 className="ppb_title" style={{}}>TOURS</h2>
                    <div className="ppb_header_content">
                        <p>We specialise in Great Ocean Road, Phillip Island, Healesville Sanctuary and Yarra Valley Wine tours. With a range of options, we will tailor the program throughout the day to meet your expectations.</p>
                        {/* <p><a className="button" style={{marginTop: '20px'}} href="#">View Plan &amp; Price</a></p> */}
                    </div>
                    </div>
                    <br className="clear" />
                </div>
                <div className="one">
                    <div className="one_half_bg textright" style={{padding: '80px'}}>
                    <h2 className="ppb_title" style={{}}>WEDDINGS</h2>
                    <div className="ppb_header_content">
                        <p>Our friendly and attentive chauffeurs offer that impeccable wedding car service that is required on the day of the wedding.</p>
                        {/* <p><a className="button" style={{marginTop: '20px'}} href="#">View Plan &amp; Price</a></p> */}
                    </div>
                    </div>
                    <div className="one_half_bg parallax" style={{backgroundImage: 'url(upload/wedding.png)', height: '550px'}} />
                    <br className="clear" />
                </div>
            </div>

                </div> 
                <Footer />  
            </div>
            </>
        )
    }
}
