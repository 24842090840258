import React, { Component } from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay ,EffectFade } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
SwiperCore.use([Autoplay,EffectFade ]);


export default class Car extends Component {

    render() {
    return (
      <>
        <div id="wrapper" className="hasbg transparent">
          <Header />
          <div className="ppb_wrapper">
            <div
              id="page_caption"
              className="hasbg parallax  withtopbar  blog_wrapper"
              style={{
                backgroundImage: "url(upload/Home-BANNER.jpg)",
                height: "15rem",
              }}
            >
              <div className="page_title_wrapper">
                <div className="page_title_inner">
                  <div className="page_title_content">
                    <h1 className="withtopbar">Our Vehicles</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Begin content */}
            <div id="page_content_wrapper" className="hasbg withtopbar ">
                <p style={{textAlign:"center" , fontSize:"20px"}}>
                Our vehicles have been carefully selected to meet the varying needs of our esteemed customers, the priority  still being given to a comfortable travel. So whether you are going away on a holiday to play golf or booking for a high profile corporate guest from overseas, we guarantee a car for every occasion! </p>
                {/* Begin content */}
                <div className="inner">
                    <div className="inner_wrapper nopadding">
                    <div id="page_main_content" className=" fixed_column">
                        <div className="standard_wrapper">
                        <div id="portfolio_filter_wrapper" className="gallery classic three_cols portfolio-content section content clearfix" data-columns={3}>
                            <div className="element grid classic2_cols animated2">
                            <div className="one gallery2 classic static filterable portfolio_type themeborder" data-id="post-2">
                                <a className="car_image" href="#">
                                <Swiper
                                    slidesPerView={1}
                                    autoplay={true}
                                    >
                                    <SwiperSlide>
                                        <img src="upload/BMW-GT5.jpg" alt="BMW 3 Series" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="upload/BMW-GT5 interior.jpg" alt="BMW 3 Series" />
                                    </SwiperSlide>
                                </Swiper>
                                </a>
                                <div className="portfolio_info_wrapper">
                                <div className="car_attribute_wrapper">
                                    <a className="car_link" href="#"><h4>BMW GT520d</h4></a>
                                    <div className="car_attribute_rating">
                                    <div className="br-theme-fontawesome-stars-o">
                                        <div className="br-widget">
                                        Dark Grey Colour Sedan
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <br className="clear" />
                                </div>
                                <br className="clear" />
                                </div>
                            </div>
                            </div>
                            <div className="element grid classic2_cols animated2">
                            <div className="one gallery2 classic static filterable portfolio_type themeborder" data-id="post-2">
                                <a className="car_image" href="#">
                                <Swiper
                                    slidesPerView={1}
                                    autoplay={true}
                                    >
                                    <SwiperSlide>
                                        <img src="upload/Lexus RX450h.jpg" alt="BMW 3 Series" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="upload/Lexus RX450h interior.jpg" alt="BMW 3 Series" />
                                    </SwiperSlide>
                                </Swiper>
                                {/* <img src="upload/bmw-3-series-sedan-wallpaper-1920x1200-05-700x466.jpg" alt="BMW 3 Series" /> */}
                                </a>
                                <div className="portfolio_info_wrapper">
                                <div className="car_attribute_wrapper">
                                    <a className="car_link" href="#"><h4>Lexus RX450h</h4></a>
                                    <div className="car_attribute_rating">
                                    <div className="br-theme-fontawesome-stars-o">
                                        <div className="br-widget">
                                        Black Colour Luxury SUV
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <br className="clear" />
                                </div>
                                <br className="clear" />
                                </div>
                            </div>
                            </div>
                            <div className="element grid classic2_cols animated2">
                                <div className="one gallery2 classic static filterable portfolio_type themeborder" data-id="post-2">
                                    <a className="car_image" href="#">
                                    <Swiper
                                    slidesPerView={1}
                                    autoplay={true}
                                    >
                                        <SwiperSlide>
                                            <img src="upload/Holden Caprice.jpg" alt="BMW 3 Series" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="upload/Holden Caprice interior.jpg" alt="BMW 3 Series" />
                                        </SwiperSlide>
                                    </Swiper>
                                    {/* <img src="upload/bmw-3-series-sedan-wallpaper-1920x1200-05-700x466.jpg" alt="BMW 3 Series" /> */}
                                    </a>
                                    <div className="portfolio_info_wrapper">
                                    <div className="car_attribute_wrapper">
                                        <a className="car_link" href="#"><h4>Holden Caprice</h4></a>
                                        <div className="car_attribute_rating">
                                        <div className="br-theme-fontawesome-stars-o">
                                            <div className="br-widget">
                                                Black Colour Luxury Sedan
                                            </div>
                                        </div>
                                        </div>
                                        
                                        <br className="clear" />
                                    </div>
                                    <br className="clear" />
                                    </div>
                                </div>
                            </div>
                            <div className="element grid classic2_cols animated2">
                                <div className="one gallery2 classic static filterable portfolio_type themeborder" data-id="post-2">
                                    <a className="car_image" href="#">
                                    <Swiper
                                    slidesPerView={1}
                                    autoplay={true}
                                    >
                                        <SwiperSlide>
                                            <img src="upload/VW Multivan.jpg" alt="BMW 3 Series" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="upload/VW Multivan interior.jpg" alt="BMW 3 Series" />
                                        </SwiperSlide>
                                    </Swiper>
                                    {/* <img src="upload/bmw-3-series-sedan-wallpaper-1920x1200-05-700x466.jpg" alt="BMW 3 Series" /> */}
                                    </a>
                                    <div className="portfolio_info_wrapper">
                                    <div className="car_attribute_wrapper">
                                        <a className="car_link" href="#"><h4>VW Multivan</h4></a>
                                        <div className="car_attribute_rating">
                                        <div className="br-theme-fontawesome-stars-o">
                                            <div className="br-widget">
                                            Black Colour Luxury Van (6 Passengers)
                                            </div>
                                        </div>
                                        </div>
                                        
                                        <br className="clear" />
                                    </div>
                                    <br className="clear" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br className="clear" />
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>

         
          </div>

          <Footer />
        </div>
      </>
    );
  }
}
