import React, { Component } from 'react'
import MobileMenu from './MobileMenu'
import jQuery from 'jquery'
import { NavLink } from 'react-router-dom';
export default class Header extends Component {
    componentDidMount()
    {
        function is_touch_device() {
            return 'ontouchstart' in window // works on most browsers 
                || 'onmsgesturechange' in window; // works on ie10
          }
          if(jQuery('#tg_smart_fixed_menu').val()==1 && jQuery('html').data('style') != 'fullscreen'  && jQuery('html').data('style') != 'fullscreen_white')
          {
              //Smart sticky menu
              if(!is_touch_device())
              {
                  var lastScrollTop = 0;
                  jQuery(window).scroll(function(event){
                     var st = jQuery(this).scrollTop();
                     if (st > lastScrollTop && st > 0){
                         jQuery('.top_bar').removeClass('scroll_up');
                         jQuery('.header_style_wrapper').removeClass('scroll_up');
                         jQuery('.header_style_wrapper').addClass('scroll_down');
                     } else {
                        jQuery('.top_bar').addClass('scroll_up');
                        jQuery('.header_style_wrapper').addClass('scroll_up');
                        jQuery('.header_style_wrapper').removeClass('scroll_down');
                     }
                     lastScrollTop = st;
                     
                     jQuery('.header_style_wrapper').attr('data-st', st);
                     jQuery('.header_style_wrapper').attr('data-lastscrolltop', lastScrollTop);
                  });
              }
              else
              {
                  var lastY;
                  jQuery(document).bind('touchmove', function (e){
                       var currentY = e.originalEvent.touches[0].clientY;
  
                       if(currentY > 200){
                           jQuery('.top_bar').addClass('scroll_up');
                          jQuery('.header_style_wrapper').addClass('scroll_up');
                          jQuery('.header_style_wrapper').removeClass('scroll_down');
                          
                       } else {
                          jQuery('.top_bar').removeClass('scroll_up');
                          jQuery('.header_style_wrapper').removeClass('scroll_up');
                          jQuery('.header_style_wrapper').addClass('scroll_down');
                       }
                       
                       jQuery('.header_style_wrapper').attr('data-pos', currentY);
                  });
              }
          }
        jQuery('#mobile_nav_icon').on( 'click', function() {
            jQuery('body').toggleClass('js_nav');
            jQuery('body').toggleClass('blur');
            jQuery('#close_mobile_menu').addClass('open');
            
             if(is_touch_device())
            {
                jQuery('body.js_nav').css('overflow', 'auto');
            }
        });
        
        jQuery('#close_mobile_menu').on( 'click', function() {
            jQuery('body').removeClass('js_nav');
            jQuery('body').removeClass('blur');
            jQuery(this).removeClass('open');
        });
        
        jQuery('.mobile_menu_close a, #mobile_menu_close').on( 'click', function() {
            jQuery('body').removeClass('js_nav');
            jQuery('body').removeClass('blur');
            jQuery('#close_mobile_menu').removeClass('open');
        });
        
        jQuery('.close_alert').on( 'click', function() {
            var target = jQuery(this).data('target');
            jQuery('#'+target).fadeOut();
        });
    
    }
    render() {
        return (
            <>
             <MobileMenu/>
             <div className="header_style_wrapper">
  {/* Begin top bar */}
  {/* <div className="above_top_bar"> */}
    {/* <div className="page_content_wrapper"> */}
      {/* <div className="social_wrapper">

        <ul>
          <li className="facebook">
              <a target="_blank" href="#">
              </a>
            </li>
        </ul>
      </div>
      <div className="top_contact_info">
        <div className="company_address">
       
        </div>
        <div>
          <div id="top_contact_number"><a href="tel:0421 800 408"><span className="ti-mobile" />0421 800 408</a></div>
        </div>
      </div> */}
      {/* <br className="clear" /> */}
    {/* </div> */}
  {/* </div> */}
  {/* End top bar */}
  <div className="top_bar hasbg">
    <div className="standard_wrapper">
      {/* Begin logo */}
      <div id="logo_wrapper">
        <div id="logo_normal" className="logo_container">
          <div className="logo_align">
            <NavLink id="custom_logo" className="logo_wrapper hidden" to="/">
              <img src="images/logo.png" alt="" width={175} height={60} />
            </NavLink>
          </div>
        </div>
        <div id="logo_transparent" className="logo_container">
          <div className="logo_align">
            <NavLink id="custom_logo_transparent" className="logo_wrapper default" to="/">
              <img src="images/logo.png" alt="" width={175} height={60} />
            </NavLink>
          </div>
        </div>
        {/* End logo */}
        <div id="menu_wrapper">
          <div id="nav_wrapper">
            <div className="nav_wrapper_inner">
              <div id="menu_border_wrapper">
                <div className="menu-main-menu-container">
                  <ul id="main_menu" className="nav">
                    <li className="menu-item current-menu-item">
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/our-services">Our Services</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/our-vehicle">Our Vehicles</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="our-price">Our Prices</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/booking">Make a Booking</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/">
                          <img className="header-covid-img" width={50} height={50} src="upload/covidlogo.png"/>
                        </NavLink>
                    </li>
                   </ul>
                </div>
              </div>
            </div>
            {/* Begin right corner buttons */}
            <div id="logo_right_button">
              {/* Begin side menu */}
              <a href="javascript:;" id="mobile_nav_icon"><span className="ti-menu" /></a>
              {/* End side menu */}
            </div>
            {/* End right corner buttons */}
          </div>
          {/* End main nav */}
        </div>
      </div>
    </div>
  </div>
</div>
   
            </>
        )
    }
}
