import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../Component/Footer";
import Header from "../Component/Header";

export default class About extends Component {
  render() {
    return (
      <>
        <div id="wrapper" className="hasbg transparent">
          <Header />
          <div className="ppb_wrapper">
            <div
              id="page_caption"
              className="hasbg parallax  withtopbar  blog_wrapper"
              style={{
                backgroundImage: "url(upload/Home-BANNER.jpg)",
                height: "15rem",
              }}
            >
              <div className="page_title_wrapper">
                <div className="page_title_inner">
                  <div className="page_title_content">
                    <h1 className="withtopbar">About us</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Begin content */}
            <div
              id="page_content_wrapper"
              className="hasbg withtopbar blog_wrapper"
            >
              <div className="inner">
                {/* Begin main content */}
                <div className="inner_wrapper">
                  <div className="page_content_wrapper" />
                  <div className="sidebar_content left_sidebar">
                    {/* Begin each blog post */}
                    <div
                      id="post-18"
                      className="post-18 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-accident tag-car-rental tag-tips"
                    >
                      <div className="post_wrapper">
                        <div className="post_content_wrapper">
                          <div className="post_img static">
                            <a href="#">
                              <img
                                width={960}
                                height={636}
                                src="upload/HoldenCapriceinterior.jpg"
                                className="attachment-grandcarrental-blog size-grandcarrental-blog wp-post-image"
                                alt=""
                              />{" "}
                            </a>
                          </div>
                          <div className="post_header_wrapper">
                            <div className="post_header">
                              <div className="post_header_title">
                                <h5>
                                  <a href="#">About Us</a>
                                </h5>
                              </div>
                            </div>
                            <br className="clear" />
                            <p>
                              From humble beginnings in the Eastern
                              suburbs of Melbourne, we have grown as a result of
                              our values and principles. We are now a team that
                              has the necessary experience, knowledge and
                              appearance needed to continually exceed your
                              expectations and allow you to travel with
                              stress-free comfort. We service all of Melbourne,
                              with a continued focus on inner eastern suburbs:
                              Balwyn, Box Hill, Camberwell, Canterbury,
                              Doncaster, Hawthorn, Kew, Malvern, Mont Albert,
                              Richmond, South Yarra, Surrey Hills and Toorak.
                              Feel free to call us anytime if you’d like a quick
                              chat to get to know us better before making a
                              booking.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br className="clear" />
                    {/* End each blog post */}
                  </div>
                  <div className="sidebar_wrapper left_sidebar">
                    <div className="sidebar_top" />
                    <div className="sidebar">
                      <div className="content">
                        <ul className="sidebar_widget">
                          <li
                            id="grandcarrental_cat_posts-3"
                            className="widget grandcarrental_Cat_Posts"
                          >
                            <h2 className="widgettitle">
                              <span>Address</span>
                            </h2>
                            <ul className="posts blog withthumb ">
                              <li>
                                <div>
                                  <h6>
                                    Encore Limo Service <br></br> 1/234 Belmore Road
                                    Balwyn, Victoria, 3103 Australia
                                  </h6>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <h6>
                                    Mobile: <br />
                                    0421 800 408
                                  </h6>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <h6>Email: encore@encorelimo.com.au</h6>
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li
                            id="grandcarrental_social_profiles_posts-4"
                            className="widget grandcarrental_Social_Profiles_Posts"
                          >
                            <h2 className="widgettitle">Connect with us</h2>
                            <div className="social_wrapper shortcode light small">
                              <iframe
                                scrolling="no"
                                frameborder="0"
                                marginheight="0"
                                marginwidth="0"
                                
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.372396073909!2d145.08857781531842!3d-37.804745479754025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad641298218f35f%3A0xd3fa59086e472e1c!2sUnit%201%2F232-234%20Belmore%20Rd%2C%20Balwyn%20VIC%203103%2C%20Australia!5e0!3m2!1sen!2s!4v1618369486878!5m2!1sen!2s"
                              ></iframe>
                              
                              <div style={{textAlign:"center"}} className="one themeborder">
                              <img width={100} style={{height:"100px"}} src="upload/covidlogo.png"/>
                              <br></br>
                              <NavLink
                                type="button"
                                id="car_search_btn"
                                className="button"
                                to="booking"
                              >
                                  Book Now
                              </NavLink>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End main content */}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}
