import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';


export default class MobileMenu extends Component {
    render() {
        return (
            <>
              <div>
              {/* <div> */}
  <input type="hidden" id="pp_menu_layout" name="pp_menu_layout" defaultValue="leftalign" />
  <input type="hidden" id="pp_enable_right_click" name="pp_enable_right_click" defaultValue={0} />
  <input type="hidden" id="pp_enable_dragging" name="pp_enable_dragging" defaultValue={0} />
  <input type="hidden" id="pp_image_path" name="pp_image_path" defaultValue="http://themes.themegoods.com/grandcarrental/demo/wp-content/themes/grandcarrental/images/" />
  <input type="hidden" id="pp_homepage_url" name="pp_homepage_url" defaultValue="index.html" />
  <input type="hidden" id="pp_fixed_menu" name="pp_fixed_menu" defaultValue={1} />
  <input type="hidden" id="tg_smart_fixed_menu" name="tg_smart_fixed_menu" defaultValue={0} />
  <input type="hidden" id="tg_sidebar_sticky" name="tg_sidebar_sticky" defaultValue={1} />
  <input type="hidden" id="pp_topbar" name="pp_topbar" defaultValue={1} />
  <input type="hidden" id="post_client_column" name="post_client_column" defaultValue={4} />
  <input type="hidden" id="pp_back" name="pp_back" defaultValue="Back" />
  <input type="hidden" id="pp_page_title_img_blur" name="pp_page_title_img_blur" defaultValue />
  <input type="hidden" id="tg_portfolio_filterable_link" name="tg_portfolio_filterable_link" defaultValue />
  <input type="hidden" id="tg_flow_enable_reflection" name="tg_flow_enable_reflection" defaultValue />
  <input type="hidden" id="tg_lightbox_skin" name="tg_lightbox_skin" defaultValue="metro-black" />
  <input type="hidden" id="tg_lightbox_thumbnails" name="tg_lightbox_thumbnails" defaultValue="horizontal" />
  <input type="hidden" id="tg_lightbox_thumbnails_display" name="tg_lightbox_thumbnails_display" defaultValue={1} />
  <input type="hidden" id="tg_lightbox_opacity" name="tg_lightbox_opacity" defaultValue="0.8" />
  <input type="hidden" id="tg_cart_url" name="tg_cart_url" defaultValue="http://themes.themegoods.com/grandcarrental/demo/cart/" />
  <input type="hidden" id="tg_live_builder" name="tg_live_builder" defaultValue={0} />
  <input type="hidden" id="pp_footer_style" name="pp_footer_style" defaultValue={3} />
{/* </div> */}

  <a id="close_mobile_menu" href="javascript:;" />
  <div className="mobile_menu_wrapper">
    <a id="mobile_menu_close" href="javascript:;" className="button"><span className="ti-close" /></a>
    <div className="mobile_menu_content">
      <div className="menu-main-menu-container">
        <ul id="mobile_main_menu" className="mobile_main_nav">
                    <li className="menu-item current-menu-item">
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink  to="/our-services">Our Services</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/our-vehicle">Our Vehicles</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/our-price">Our Prices</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/booking">Make a Booking</NavLink>
                    </li>
        </ul>
      </div>
      {/* Begin side menu sidebar */}
      <div className="page_content_wrapper">
        <div className="sidebar_wrapper">
          <div className="sidebar">
            <div className="content">
              <ul className="sidebar_widget">
                <li id="text-8" className="widget widget_text">
                  <h2 className="widgettitle">For More Informations</h2>
                  <div className="textwidget"><span className="ti-mobile" style={{marginRight: '10px'}} />1-567-124-44227
                    <br />
                    <span className="ti-alarm-clock" style={{marginRight: '10px'}} />Mon - Sat 8.00 - 18.00</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End side menu sidebar */}
      <div className="social_wrapper">
        <ul>
          <li className="facebook"><a target="_blank" href="#"><i className="fa fa-facebook-official" /></a></li>
          <li className="twitter"><a target="_blank" href="http://twitter.com/#"><i className="fa fa-twitter" /></a></li>
          <li className="youtube"><a target="_blank" title="Youtube" href="#"><i className="fa fa-youtube" /></a></li>
          <li className="pinterest"><a target="_blank" title="Pinterest" href="http://pinterest.com/#"><i className="fa fa-pinterest" /></a></li>
          <li className="instagram"><a target="_blank" title="Instagram" href="http://instagram.com/theplanetd"><i className="fa fa-instagram" /></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
  
            </>
        )
    }
}
