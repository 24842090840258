import React, { Component } from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";

export default class Price extends Component {
  render() {
    return (
      <>
        <div id="wrapper" className="hasbg transparent">
          <Header />
          <div className="ppb_wrapper">
            <div
              id="page_caption"
              className="hasbg parallax  withtopbar  blog_wrapper"
              style={{
                backgroundImage: "url(upload/Home-BANNER.jpg)",
                height: "15rem",
              }}
            >
              <div className="page_title_wrapper">
                <div className="page_title_inner">
                  <div className="page_title_content">
                    <h1 className="withtopbar">Our Price</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Begin content */}
            <div
              id="page_content_wrapper"
              className="hasbg withtopbar blog_wrapper"
            >
              <h1>Our Prices</h1>

              <div className="single_car_attribute_wrapper themeborder">
                <p>
                We believe our reliable, friendly and professional services account for great value for your money. Please feel free to contact us for an accurate estimate catered to your suburb.
                </p>
              </div>
              <br className="clear" />
              <div className="single_car_content">
                <h4 className="p1">PLEASE NOTE:</h4>
                <ul>
                  <li>
                    Prices are quoted as per vehicle and includes GST, tolls and parking.
                  </li>
                  <li>
                    We accept all modes of payment including CabCharge and all major credit and debit cards.
                  </li>
                  <li>
                    We provide the most worthy combination of travelling via the fastest route at the most affordable price and we pride ourselves on being one the few limo services that offer luxury rides at a cost barely above taxi fare.
                  </li>
                  <li>
                    Unlike Uber / taxi services, our prices are maintained whether you travel during peak hour or not!
                  </li>
                  <li>
                    We offer a most convenient system of monthly invoicing in which we bypass all the paperwork for you by sending single invoice at the end of each month outlining all travels during that month and the account payment will only be a once off hassle. Our customers have found this especially useful where they needed transport for conference participants or multiple guests arriving different days and times. 
                  </li>
                  <li>
                    We also offer account setup for corporate travellers individually where we collect EFTPOS payments on card the next day of travel and email the receipts. The benefits: you don’t need to carry cash, no hassle to pay the driver while you are hurriedly exiting the car or busy on a phone call and lastly receipts can be kept on e-storage reducing the need for paper records.
                  </li>
                  {/* <li>
                    We pride ourselves on being one the few limo services that
                    offer luxury rides at a cost barely above taxi fare.
                  </li> */}
                  
                  
                  
                </ul>
                <br/>
                {/* <p className="p2">
                  Meh synth Schlitz, tempor duis single-origin coffee ea next
                  level ethnic fingerstache fanny pack nostrud. Photo booth anim
                  8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse
                  nihil, flexitarian Truffaut synth art party deep v chillwave.
                  Seitan High Life reprehenderit consectetur cupidatat kogi. Et
                  leggings fanny pack, elit bespoke vinyl art party Pitchfork
                  selfies master cleanse.
                </p>
                <p className="p2">
                  Craft beer elit seitan exercitation, photo booth et 8-bit kale
                  chips proident chillwave deep v laborum. Aliquip veniam
                  delectus, Marfa eiusmod Pinterest in do umami readymade swag.
                  Selfies iPhone Kickstarter, drinking vinegar jean vinegar
                  stumptown yr pop-up artisan sunt. Craft beer elit seitan
                  exercitation, photo booth
                </p>
                <p className="p2">
                  See-through delicate embroidered organza blue lining luxury
                  acetate-mix stretch pleat detailing. Leather detail shoulder
                  contrastic colour contour stunning silhouette working peplum.
                  Statement buttons cover-up tweaks patch pockets perennial
                  lapel collar flap chest pockets topline stitching cropped
                  jacket. Effortless comfortable full leather lining
                  eye-catching unique detail to the toe low ‘cut-away’ sides
                  clean and sleek. Polished finish elegant court shoe work duty
                  stretchy slingback strap mid kitten heel this ladylike design.
                </p> */}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}
