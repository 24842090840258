import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        return (
            <>
              <div>
  <div className="footer_bar  ppb_wrapper ">
    <div className="footer_bar_wrapper ">
      <div className="menu-footer-menu-container">
        <ul id="footer_menu" className="footer_nav">
        <li className="menu-item current-menu-item">
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink  to="/our-services">Our Services</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/our-vehicle">Our Vehicles</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/our-price">Our Prices</NavLink>
                    </li>
                    <li className="menu-item">
                        <NavLink to="/booking">Make a Booking</NavLink>
                    </li>
        </ul>
      </div>
      <div id="copyright">© Copyright Encore Limo Service</div>
      <br className="clear" />
    </div>
  </div>
</div>
  
            </>
        )
    }
}
