import React, { Component } from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";

export default class Contact extends Component {
  render() {
    return (
      <>
        <div id="wrapper" className="hasbg transparent">
          <Header />
          <div className="ppb_wrapper">
            <div
              id="page_caption"
              className="hasbg parallax  withtopbar  blog_wrapper"
              style={{
                backgroundImage: "url(upload/Home-BANNER.jpg)",
                height: "15rem",
              }}
            >
              <div className="page_title_wrapper">
                <div className="page_title_inner">
                  <div className="page_title_content">
                    <h1 className="withtopbar">Contact</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Begin content */}
            <div
              id="page_content_wrapper"
              className="hasbg withtopbar blog_wrapper"
              style={{width:"60%"}}
            >
              <div className="ppb_wrapper hasbg withtopbar">
                <div
                  className="one withsmallpadding ppb_text"
                  style={{
                    textAlign: "left",
                    padding: "0px 0 0px 0",
                    marginBottom: "60px",
                  }}
                >
                  <div className="standard_wrapper">
                    <div className="page_content_wrapper">
                      <div className="inner">
                        <div style={{ margin: "auto", width: "60%" }}>
                          <div
                            role="form"
                            className="wpcf7"
                            id="wpcf7-f2465-o1"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response" />
                            <form
                              className="quform"
                              action=""
                              method="post"
                              encType="multipart/form-data"
                              onclick
                            >
                              <div className="quform-elements">
                                <div className="quform-element">
                                  <br />
                                  <span className="wpcf7-form-control-wrap your-name">
                                    <input
                                      id="name"
                                      type="text"
                                      name="name"
                                      size={40}
                                      className="input1"
                                      aria-required="true"
                                      aria-invalid="false"
                                      placeholder="Name*"
                                    />
                                  </span>
                                </div>
                                <div className="quform-element">
                                  <br />
                                  <span className="wpcf7-form-control-wrap your-email">
                                    <input
                                      id="email"
                                      type="text"
                                      name="email"
                                      size={40}
                                      className="input1"
                                      aria-required="true"
                                      aria-invalid="false"
                                      placeholder="Email*"
                                    />
                                  </span>
                                </div>
                                <div className="quform-element">
                                  <br />
                                  <span className="wpcf7-form-control-wrap your-message">
                                    <textarea
                                      id="message"
                                      name="message"
                                      cols={40}
                                      rows={10}
                                      className="input1"
                                      aria-invalid="false"
                                      placeholder="Message*"
                                      defaultValue={""}
                                    />
                                  </span>
                                </div>
                                {/* Begin Submit button */}
                                <div className="quform-submit">
                                  <div className="quform-submit-inner">
                                    <button
                                      type="submit"
                                      className="submit-button"
                                    >
                                      <span>Send</span>
                                    </button>
                                  </div>
                                  <div className="quform-loading-wrap">
                                    <span className="quform-loading" />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}
