import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../Component/Footer";
import Header from "../Component/Header";

export default class Home extends Component {
  render() {
    return (
    <div id="wrapper" className="hasbg transparent">
      <Header/>
      <div className="ppb_wrapper">
        <div
          className="one withsmallpadding ppb_car_search_background parallax withbg "
          style={{
            paddingTop: "150px !important",
            textAlign: "center",
            height: "800px",
            backgroundImage: "url(upload/Home-BANNER.jpg)",
            backgroundPosition: "center center",
            color: "#ffffff",
          }}
        >
          <div
            className="overlay_background"
            style={{ background: "rgba(0,0,0,0.2)" }}
          />
          <div className="center_wrapper">
            <div className="inner_content">
              <div className="standard_wrapper">
                <h2 className="ppb_title" style={{ color: "#ffffff" }}>
                   Get a whole lot more
                </h2>
                <div className="page_tagline" style={{ color: "#ffffff" }}>
                For only a little more than a taxi, we provide seamless door-to-door transport services that are
                </div><br/>
                <div className="page_tagline" style={{ color: "#ffffff" }}>Prompt, Reliable and Professional</div>
                <div className="car_search_form" method="get" action="#">
                  <div className="car_search_wrapper">
                    <div className="one themeborder">
                      <NavLink
                        type="button"
                        id="car_search_btn"
                        className="button"
                        to="booking"
                      >
                          Book Now
                      </NavLink>
                      
                    </div>
                    {/* <div className="one themeborder" style={{marginTop:"10px"}}>
                    <img width={50} height={50} src="upload/covidlogo.png"/>
                    </div> */}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="one withsmallpadding ppb_header "
          style={{ textAlign: "center", padding: "60px 0 60px 0" }}
        >
          <div className="standard_wrapper">
            <div className="page_content_wrapper">
              <div className="inner">
                <div style={{ margin: "auto", width: "100%" }}>
                  <h2 className="ppb_title" style={{}}>
                      Make the Switch
                  </h2>
                  <div className="page_tagline" style={{}}>
                     <p>
                     Going to the Airport? Are you a corporate traveller or holidaymaker and looking for a prompt and professional transport service? Don’t risk missing your flight! We specialise in airport transfers, so you can relax and unwind, as we ensure you’re on time, every time! Do you have a special event coming up or looking for a personal driver for you and the family? Feel relaxed, safe and secure as our friendly and dedicated chauffeurs attend to your transport needs in our luxury cars that are in showroom condition.
                     </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ppb_car_brand_grid one nopadding ">
          <div className="page_content_wrapper page_main_content sidebar_content full_width fixed_column">
            <div className="standard_wrapper">
              <div
                id={15722572661666401717}
                className="portfolio_filter_wrapper gallery grid portrait three_cols"
                data-columns={3}
              >
                <div className="element grid classic3_cols animated1">
                  <div
                    className="one_third gallery3 grid static filterable portfolio_type themeborder"
                    style={{
                      backgroundImage: "url(upload/ea6f129477bde40715ee151ebf74090e.jpg)",
                      backgroundSize:"125% 100%"
                    }}
                  >
                    <a className="car_image" href="#" />
                    <div className="portfolio_info_wrapper">
                      <h3>Mercedes</h3>
                    </div>
                  </div>
                </div>
                <div className="element grid classic3_cols animated2">
                  <div
                    className="one_third gallery3 grid static filterable portfolio_type themeborder"
                    style={{
                      backgroundImage:
                        "url(upload/thumb2-bmw-m5-f90-2018-front-view-exterior.jpg)",
                        backgroundSize:"125% 100%"
                    }}
                  >
                    <a className="car_image" href="#" />
                    <div className="portfolio_info_wrapper">
                      <h3>BMW</h3>
                    </div>
                  </div>
                </div>
                <div className="element grid classic3_cols animated3">
                  <div
                    className="one_third gallery3 grid static filterable portfolio_type themeborder"
                    style={{
                      backgroundImage:
                        "url(upload/wp6633535.jpg)",
                        backgroundSize:"125% 100%"
                    }}
                  >
                    <a className="car_image" href="#" />
                    <div className="portfolio_info_wrapper">
                      <h3>Lexus</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="one withsmallpadding ppb_header_youtube withbg parallax"
          data-jarallax-video="https://www.youtube.com/watch?v=D3tv6J7tk0k"
          style={{
            textAlign: "center",
            // padding: "215px 0 215px 0",
            color: "#ffffff",
          }}
        >
          <div
            className="overlay_background"
            style={{ background: "rgba(0,0,0,0.5)" }}
          />
          <div className="standard_wrapper">
            <div className="page_content_wrapper">
              <div className="inner">
                <div style={{ margin: "auto", width: "100%" }}>
                  {/* <h2 className="ppb_title" style={{ color: "#ffffff" }}>
                    Get a whole lot more
                  </h2>
                  <div className="page_tagline" style={{ color: "#ffffff" }}>
                  For only a little more than a taxi, we provide seamless door-to-door transport services that are:
                    <br /> Prompt, Reliable , Professional , Personable , Affordable 
                  </div> */}
                  <div className="ppb_header_content">
                    <p>
                      <span style={{ fontSize: "32px" }}>
                        Call Now 0421 800 408
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* <div
          className="one withsmallpadding ppb_text"
          style={{
            paddingTop: "0 !important",
            textAlign: "center",
            padding: "10px 0 10px 0",
            backgroundColor: "#5856d6",
            color: "#ffffff !important",
          }}
        >
          <div className="standard_wrapper">
            <div className="page_content_wrapper">
              <div className="inner">
                <div style={{ margin: "auto", width: "100%" }}>
                <h2 className="ppb_title" style={{ color: "#ffffff" }}>
                        Make the Switch
                  </h2>
                  <div className="one " style={{}}>
                    <p style={{color:"white"}}>
                    Going to the Airport? Are you a corporate traveller or holidaymaker and looking for a prompt and professional service? Don’t risk missing your flight! We specialise in airport transfers, so relax and unwind, as we’ll ensure you’re on time, every time!
                  Do you have a special event coming up? Or looking for a personal driver for you and the family? Feel relaxed, safe and secure as our friendly and dedicated chauffeurs attend to your transport needs in our private cars that are in showroom condition.
                  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer/>
    </div>
    );
  }
}
